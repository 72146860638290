/* src/pages/Traffic.css */


html,body {
  font-family: 'segoe ui', Arial, sans-serif;
 
  margin: 0;
  padding: 0;
}
#theme-container{
  
  width: 100vw!important;
  height: 100vh!important;
}
#trafficCalc {
  width: 100vw;
  height: 100vh;
  padding: 2rem;
  margin:0;
  display:block;
}
#theme-container.light {

  * {
    color: #444;
    background-color: #fff;
  }

  button {
    border: solid 2px #a8a8a8;
    background-color: #e4e4e4;
  }
  #theme{
    background-color: transparent;
  }
}

#theme-container.dark {
  * {
    color: #ddd;
    background-color: #292f3a;
  }

  button {
    border: solid 2px #898989;
    background-color: #3c3c3c;
  }
  #theme{
    background-color: transparent;
  }
}


.theme-switcher button {
  float: right;
  clear: none;
  height: 30px;
  width: 30px;
  padding: 0;

}



h1 {
  font-weight: 300;
  font-size:2rem;
  margin: 0;
  padding:0;
}

textarea {
  width: 100%;
  height: 10rem;
  margin-bottom: 1rem;
  padding: .75rem;
  font-size: 1.1rem;
  border: solid 1px #a5a5a5;
  border-radius: 5px;

}


button {
  padding: 1rem 1rem;
  margin:0 1rem 0 0;
  font-size: 16px;
  border-radius: .5rem;
  border: solid 2px #a8a8a8;
  background-color: #e4e4e4;
 
}

p {
  font-size: 1rem;
  margin: .5rem 0;
  padding: 0;
}

p.disclaimer {
  font-size: .85rem
}

#results {
  margin-top: 20px;
  width: 90%;

  width: 90%;

}


.result-item {
  padding: .25rem .85rem;
  border-left: .5rem solid #d7d2ff;
  margin-bottom: 1.5rem;
  /* border-radius: 5px; */
  padding: .25rem .85rem;
  border-left: .5rem solid #d7d2ff;
  margin-bottom: 1.5rem;
  /* border-radius: 5px; */
}


#downloadBtn {
  /* margin-top: 10px; */
  display: none;
  /* Hidden until results are available */
  /* margin-top: 10px; */
  display: none;
  /* Hidden until results are available */
}
#clearURLs{
  float:right;
  display: none;
}
#clearURLs{
  float:right;
  display: none;
}