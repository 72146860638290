:root {
  --dark-black: #151518;
  --black: #1c1b20;
  --green: #6ecea4;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Globals */
body {
  background: #ffffff;
  color: var(--dark-black);
  width: 100vw;
  overflow-x: hidden !important;
}
html {
  font-family: "Satoshi", sans-serif;
  scroll-behavior: smooth;
}
.container {
  width: 90%;
  max-width: 1440px;
  margin: 0 auto;
}
a {
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
  color: var(--black);
}
ul {
  list-style: none;
}
img {
  max-width: 100%;
}
h1,
h2,
h3,
h4 {
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
h1 {
  font-weight: 900;
  font-size: 2.5rem;
}
h2 {
  font-size: 2.15rem;
}
h3 {
  font-size: 2rem;
}
h4 {
  font-size: 1.5rem;
}
h5 {
  font-size: 1.25rem;
}

.flex {
  display: flex;
}
.align-center {
  align-items: center;
}
.align-start {
  align-items: flex-start;
}
.align-end {
  align-items: flex-end;
}
.justify-between {
  justify-content: space-between;
}
.justify-start {
  justify-content: flex-start;
}
.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: flex-end;
}
.flex-col {
  flex-direction: column;
}
.show {
  display: block !important;
}
.hide {
  display: none !important;
}
.italic {
  font-style: italic;
}
.text-green {
  color: var(--green);
}
.text-white {
  color: white;
}
.bold {
  font-weight: 700 !important;
}
img {
  max-width: 100% !important;
  max-height: 100%;
}

@media screen and (max-width: 1390px) {
  html {
    font-size: 90%;
  }
}
@media screen and (max-width: 1024px) {
  html {
    font-size: 86%;
  }
}
@media screen and (max-width: 767px) {
  .container {
    width: 90% !important;
    margin: 0 auto;
  }
  .mobile-hide {
    display: none !important;
  }
  h1 {
    font-size: 2.616rem;
  }
  h2,
  h4,
  h3 {
    font-size: 2.326rem;
  }
  .btn {
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .desktop-hide {
    display: none;
  }
}
@media screen and (min-width: 950px) {
  .tab-hide {
    display: none;
  }
}

/* Global End */
.main-body {
  min-height: 100vh;
}
.logo {
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 900;
  line-height: 130%;
}
.form-body {
  width: 51%;
}
.form-image {
  width: 49%;
  background: url("../public/images/banking-app-bg.jpg");
  background-size: contain;
  background-position: center;
}
.form-image img {
  margin: 5rem 0;
}
.form-body-container {
  width: 92%;
  margin: 0 auto;
  padding-top: 1.8rem;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.form {
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 3rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
form {
  width: 90%;
}
.form p {
  font-size: 1.125rem;
  opacity: 0.699999988079071;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  width: 85%;
  max-width: 311px;
  margin: 0.75rem auto;
}
.form-group {
  width: 100%;
  max-width: 435px !important;
  margin: 1rem auto;
  gap: 0.45rem;
}
.form-group input,
.form-group textarea {
  width: 100%;
  border-radius: 10px;
  border: 1px solid var(--stroke, #f0eef5);
  padding: 0.9rem 0.8rem;
  font-family: "Satoshi";
  font-size: 1rem;
}
.form-group input::placeholder {
  font-family: "Satoshi";
  opacity: 0.30000001192092896;
}
.form-group input:focus {
  outline: 1px solid #333;
}
.form-group .checkbox {
  width: auto;
}
.form-group label {
  color: var(--black);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 18.2px */
}
.form-group span {
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
.form-group input[type="checkbox"] {
  width: 20px;
  height: 20px;
  border-radius: 60px !important;
  accent-color: var(--green);
  cursor: pointer;
}
.form-group .btn,
.form-group button a,
.numpad .btn,
.money-transfered .btn,
.loan-interest .btn {
  width: 100%;
  padding: 0.9rem 0;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  background: #b2edd3;
  font-size: 0.938rem;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  transition: opacity 0.2s ease;
}
.form-group .btn:hover,
.numpad .btn:hover {
  opacity: 0.9;
}
.from-btn-small a {
  font-size: 0.938rem;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  display: inline-block;
  padding: 8px 12px;
  width: auto;
  border-radius: 100px;
  border: 1px solid var(--stroke, #f0eef5);
  background: var(--white, #fff);
  transition: background 0.25s ease;
}
.from-btn-small a:hover {
  background: var(--white, #f0eef5);
}
.password-wrap {
  width: 100%;
  position: relative;
}
.eye {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.3;
}
.forgot {
  font-size: 0.875rem;
  font-weight: 700;
  text-decoration-line: underline;
}
header {
  width: 100%;
  z-index: 5;
}
.back-btn {
  cursor: pointer;
  gap: 0.8rem;
  font-weight: 700;
  border: none;
}
.back-btn:hover {
  opacity: 0.85;
}
.eye:focus-visible {
  outline: 1px solid #000;
  opacity: 0.5;
}

@media screen and (max-width: 900px) {
  .main-body {
    flex-direction: column;
    align-items: center;
  }
  .form-image {
    display: none;
  }
  .form {
    margin-top: 6rem;
    padding: 0;
  }
  .form-body {
    width: 100%;
  }
  .form-body-container {
    width: 100%;
  }
  .form-body-container header {
    padding: 0 2rem;
  }
  form {
    width: 93%;
  }
  .signIn .form {
    margin-top: 6rem;
  }
}

/* ------ Dashboard ---- */
.dashboard-container {
  /* border: 1px solid black; */
  height: 100vh;
  /* padding: 0.55rem; */
  padding-right: 0;
  /* overflow-x: hidden; */
}
.sidebar-container {
  width: 22%;
  height: 100vh;
  padding: 0.55rem;
}
.sidebar {
  /* position: fixed; */
  width: 100%;
  height: 100%;
  margin: auto;
  /* top: 50%;
  left: 0.55rem; */
  padding: 0.55rem;
  /* transform: translateY(-50%); */
  /* transform: translate(0.55rem); */
  background: #1c1b20;
  color: #b6b6b6;
  border-radius: 20px;
  padding: 1.6rem 1.25rem;
}

.sidebar header {
  margin-bottom: 2.4rem;
}
.sidebar nav li {
  margin: 0.7rem 0;
}
.sidebar nav li a {
  border-radius: 10px;
  font-size: 0.938rem;
  color: #8e8d90;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 9.5px 10px;
  gap: 0.45rem;
}
.nav-active {
  color: #1c1b20 !important;
  background: #b2edd3;
}
.main {
  width: 100%;
  padding-right: 1.5rem;
  margin-left: 1rem;
  scroll-behavior: smooth !important;
  overflow-y: scroll;
  overflow-x: hidden !important;
  position: relative;
}
.main-head {
  padding: 1rem 0;
  padding-right: 1rem;
  /* border: 1px solid red; */
}
.main-content {
  /* height: 100%; */
  padding-top: 1rem;
  gap: 1.15rem;
}
.main-content_left {
  width: 57%;
  /* border: 1px solid green; */
}
.main-content_right {
  width: 43%;
  /* border: 1px solid orange; */
  padding-left: 10px;
}
.cards {
  justify-content: space-between;
}
.cards img {
  border-radius: 20px;
  width: 48.8%;
}
.main section h2 {
  color: #1c1b20;
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: 130%; /* 31.2px */
  margin-bottom: 0.8rem;
}
.actual-balance {
  width: 100%;
  height: 180px;
  flex-shrink: 0;
  border-radius: 20px;
  border: 1px solid var(--stroke, #f0eef5);
  background: var(--white, #fff);
  gap: 1rem;
}
.actual-balance p {
  color: #1c1b20;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 20.8px */
}
.income-outcome {
  gap: 2rem;
}
.gap-sm {
  gap: 0.65rem;
}
.gap-m {
  gap: 0.85rem;
}
.gap-l {
  gap: 1.15rem;
}
.gap-xl {
  gap: 1.45rem;
}
.gap-2xl {
  gap: 1.9rem;
}
.recent-transactions-container {
  margin-top: 2rem;
}
.recent-transactions {
  /* border: 1px solid pink; */
  width: 100%;
}
.recent-transactions tr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin: 0.85rem 0;
}
.recent-transactions tbody tr:hover {
  background-color: #f0eef5;
}
.recent-transactions thead {
  display: block;
  margin-bottom: 0.6rem;
}
th,
td {
  padding: 8px;
  text-align: left;
}
th.name,
td.name {
  width: 46%;
}

th.status,
td.status {
  width: 23%;
}

th.type,
td.type {
  width: 23%;
}

th.date,
td.date {
  width: 29%;
}

th.date-tx,
td.date-tx {
  width: 20%;
}

th.amount,
td.amount {
  width: 25%;
  text-align: right;
}
th.amount-tx,
td.amount-tx {
  width: 18%;
  /* border: 1px solid red; */
  text-align: right;
}
/* For Webkit based browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 4.5px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Color of the track */
  border-radius: 60px;
}

::-webkit-scrollbar-thumb {
  background-color: #a5a5a5; /* Color of the thumb */
  border-radius: 5px; /* Rounded corners for the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color of the thumb on hover */
}
.send-receive-container {
  margin-top: 1.35rem;
}
.send-receive-container button {
  cursor: pointer;
  border-radius: 14px;
  border: 1px solid #f0eef5;
  background: var(--white, #fff);
  width: 47%;
  padding: 1.1rem;
}
.send-receive-container div:hover {
  background: var(--white, #f8f8f8af);
}
.receive img {
  transform: rotate(180deg);
}
.loans-section {
  border-radius: 14px;
  border: 1px solid #f0eef5;
  background: var(--white, #fff);
  margin-top: 1.35rem;
  padding: 4rem 0;
}
.loans-section h4 {
  color: #1c1b20;
  font-size: 1rem;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}
.loans-section p {
  color: #1c1b20;
  text-align: center;
  font-family: Satoshi;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 67%;
  margin: 0 auto;
}
.btn-small {
  cursor: pointer;
  border-radius: 10px;
  background: #b2edd3;
  border: none;
  padding: 8px 16px;
  font-size: 0.85rem;
  font-style: normal;
  font-weight: 900;
}

.modal-container {
  width: 43.4%;
  background-color: #fff;
  min-height: 100vh;
  position: absolute;
  top: 0rem;
  right: 0px;
  z-index: 99 !important;
  /* border: 1px solid red; */
  box-shadow: -3px 0px 8px 0 #0000001a;
  padding-bottom: 2.2rem;
  transform: translateX(100%);
  /* display: none; */
  /* animation-name: slideIn; */
  animation-duration: 0.3s; /* Adjust the duration as needed */
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  /* border-top-left-radius: 20px; */
  border-bottom-left-radius: 20px;
  /* visibility: hidden; */
  /* overflow-y: scroll; */
}

.modal-container header {
  padding: 0 1.75rem;
}
.modal-container header {
  padding-top: 1.5rem;
}
@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes slideOut {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}
.upper {
  background: #b2edd3;
  padding: 1.8rem 0;
  padding-top: 0rem;
}
.upper div {
  margin: 5rem 0;
  margin-top: 3.85rem;
}
.upper div img {
  width: 56px;
  height: 56px;
}
.tx-status {
  width: auto;
  display: block;
  border-radius: 6px;
  background: #f9e18d;
  padding: 3px 8px;
  font-size: 0.938rem;
  font-style: normal;
  font-weight: 700;
  margin-top: 0.25rem;
}
.tx-cancelled {
  background: #fea8c2 !important;
}
.tx-completed {
  background: #b2edd3 !important;
}
.card-bordered {
  border-radius: 20px;
  border: 1px solid #f0eef5;
  background: #fff;
}
.p-12 {
  padding: 16px;
}
.tx-income {
  padding: 3rem 1rem;
}
.tx-income h2 {
  font-size: 4rem !important;
  font-style: normal;
  font-weight: 700;
}
.card-sm {
  margin-top: 0.7rem;
}
.card-sm p {
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
}
.opacity-05 {
  /*opacity: 0.5 !important;*/
  color: rgba(0,0,0,0.5);
}
.lower {
  padding: 0 2rem;
  margin-top: -4.8rem;
}
#back-btn,
#back-btn1,
#back-btn2,
#back-btn3 {
  background: transparent;
}
.send-money {
  margin-top: 1.8rem;
}
.send-money,
.last-transfers,
.my-cards,
.send-money-two {
  padding: 0.5rem 1.75rem;
}
.send-money h3,
.money-transfered h3 {
  color: #1c1b20;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 900;
  line-height: 130%; /* 31.2px */
}
.send-money label,
.modal-container h5 {
  display: inline-block;
  font-size: 0.938rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  margin: 0.8rem 0 0.5rem 0;
}
.modal-container h5 {
  margin-bottom: 1.1rem;
  margin-top: 1.3rem;
}
.last-transfers h5 {
  margin-bottom: 0rem;
}
.search-wrap {
  width: 100%;
}
.search-wrap input {
  width: 100%;
  border: none;
  border-radius: 10px;
  background: #f6f5f9;
  padding: 0.9rem;
  padding-left: 42px;
  font-size: 0.88rem;
}
.search-wrap input::placeholder {
  opacity: 0.3;
}
.input-wrap {
  width: 100%;
  /* border: 1px solid red; */
  border-radius: 10px;
  background: #f6f5f9;
  position: relative;
}
.input-wrap img {
  position: absolute;
  left: 10px;
}
.my-cards span,
.last-transfers span {
  font-size: 0.938rem;
  font-weight: 700;
}
.text-light {
  font-size: 0.813rem;
  font-weight: 500;
}
.last-transfers div {
  margin: 1.25rem 0;
}
.last-transfers-item {
  cursor: pointer;
}
.last-transfers-item:hover {
  background: #f5f5f5;
}
#last-transfers-search {
  display: none !important;
  visibility: "hidden" !important;
}
.text-center {
  text-align: center;
}
.sender {
  margin-top: 1.1rem;
  border-radius: 16px;
  border: 1px solid var(--stroke, #f0eef5);
  padding: 0.5rem;
}
.sender-amount {
  width: 100%;
  /* border: 1px solid red; */
  padding: 2rem 0.5rem;
  padding-bottom: 3.5rem;
}
.sender-amount-value h3 {
  font-size: 4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}
.opacity-03 {
  opacity: 0.3;
}
.sender-amount-value h3 span {
  margin-left: 0.4rem;
}
.numpad {
  width: 100%;
  text-align: center;
  padding: 0rem 1.8rem;
  padding-bottom: 4rem;
  /* border: 1px solid blue; */
}
.numpad .btn {
  padding: 1.2rem 0;
}
.numpad-keys button {
  cursor: pointer;
  border-radius: 16px;
  background: var(--stroke, #f0eef5);
  padding: 20px;
}
.numpad-keys {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0.625rem;
  margin-top: 1rem;
}

.numpad-btn {
  padding: 10px;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 900;
  border: 1px solid rgba(255, 0, 0, 0);
  transition: border 0.225s ease;
}
.numpad-btn:hover,
.numpad-remove-btn:hover {
  border: 1px solid #8b8b8b;
}

.numpad-remove-btn {
  padding: 10px;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 900;
  background: #fff !important;
  color: #fff;
  border: 1px solid rgba(255, 0, 0, 0);
  z-index: 3;
}

.numpad-empty-btn {
  visibility: hidden;
}
.money-transfered {
  padding: 9rem 1.8rem;
  width: 90%;
  margin: 0 auto;
}
.money-transfered p {
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  opacity: 0.5;
}
.money-transfered .btn {
  padding: 1rem 0 !important;
  margin-top: 0.8rem;
}

/* -------- Transactions Page ------- */
.transactions-pg-table {
  position: relative;
}
.dropdown {
  position: relative;
  display: inline-block;
  z-index: 80 !important;
}

.dropdown-btn {
  border-radius: 12px;
  border: 1px solid var(--stroke, #f0eef5);
  background: var(--white, #fff);
  padding: 10px;
  cursor: pointer;
  padding: 11px 12px;
}

.dropdown-content {
  position: absolute;
  border-radius: 12px;
  border: 1px solid var(--stroke, #f0eef5);
  box-shadow: 0px 5px 20px 0px rgba(13, 29, 25, 0.1);
  min-width: 160px;
  z-index: 80 !important;
  top: 110%;
  right: 0;
  text-align: center;
  padding: 0.7rem 0.5rem;
}

.dropdown-content .btn {
  cursor: pointer;
  text-align: center;
  color: #333;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  background: white;
  border: none;
  margin: 0 auto;
}

.dropdown-content a,
.dropdown-btn {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

@media screen and (max-width: 1020px) {
  .sidebar-container {
    width: 15%;
  }
  .sidebar {
    padding: 1.6rem 1.1rem;
  }
  .main-content {
    width: 100%;
    flex-direction: column !important;
  }
  .main-content_left {
    width: 59%;
  }
  .main-content_right {
    width: 59%;
  }
  .modal-container {
    position: fixed;
    height: 100vh;
    width: 33%;
    overflow-y: scroll;
  }
  .sender-amount-value h3 {
    font-size: 3rem;
  }
  .numpad-keys button {
    padding: 14px;
  }
  .lower {
    padding: 0 0.4rem;
    border-left: 1px solid #f6f6f6;
  }
  .sidebar nav li a {
    font-size: 0.8rem;
    flex-direction: column;
  }
  .loans-calculator {
    width: 100% !important;
    padding: 1.35rem 1.35rem;
    padding-bottom: 2rem;
  }
  .loans-information {
    width: 100% !important;
    padding: 1.35rem 1.5rem;
    padding-bottom: 2rem;
  }
  .graph-points {
    flex-wrap: wrap;
    gap: 0.85rem;
  }
}

@media screen and (max-width: 768px) {
  .logo {
    font-size: 1.5rem;
  }
  .dashboard-container {
    flex-direction: column;
  }
  .main-content_left {
    width: 100%;
  }
  .main-content_right {
    width: 100%;
  }
  .modal-container {
    width: 100%;
  }
  .sidebar-container {
    width: 100%;
    height: auto;
  }
  .sidebar {
    width: 100%;
    padding: 0.65rem 1.1rem;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .sidebar header {
    margin-bottom: 0;
  }
  .mobile-nav-btn {
    background: transparent;
    border: none;
    cursor: pointer;
  }
  .mobile-nav-btn img {
    width: 35px;
    height: 35px;
  }
  .mobile-nav {
    width: 100%;
    transform-origin: top;
    transition: height 3s ease, transform 0.3s ease;
  }
  .mobile-nav ul{
    width: 100%;
  }
  .mobile-nav-hide {
    height: auto;
    transform: scaleY(1);
  }

  .main-head h1 {
    font-size: 2rem;
  }
  .card-row {
    flex-direction: column;
    gap: 2rem;
  }

  .card-details .card-img {
    /* border: 1px solid red; */
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 0.65rem;
  }
  .spending-limit,
  .number-date {
    width: 100%;
    /* border: 1px solid green; */
  }
  .cash-flow {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0rem;
    padding: 1rem 0rem;
  }
  .cash-flow img {
    width: 30px;
    height: 30px;
  }
  .cash-flow-item {
    padding: 0.65rem 1.5rem !important;
    gap: 0.65rem !important;
  }
  .cash-flow h4 {
    font-size: 0.8rem !important;
  }
  .cash-flow p {
    font-size: 1rem !important;
  }
  .main-content_right {
    padding-left: 0px;
  }
  .all-tx-text {
    display: none;
    font-size: 1rem;
  }
  .filter-btns {
    margin-left: auto;
  }
}

.faq-section {
  padding: 0px;
}

.faq-item {
  /* margin-bottom: 10px; */
  padding: 20px;
  /* background-color: #f1f1f1; */
  border-radius: 5px;
  transition: max-height 0.1s ease, font-size 0.1s ease;
  border-radius: 12px;
  overflow: hidden;
  background: #fff;
  padding: 1rem;
}

.faq-item:nth-child(odd) {
  background: #f6f5f9 !important;
}

.faq-question {
  margin: 0;
  cursor: pointer;
  border-radius: 12px;
  /* margin-bottom: 0.7rem; */
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
}

.faq-answer {
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
}
.faq-ans-heading {
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 700;
}
.faq-answer p {
  margin: 0.55rem 0;
}
.faq-media {
  border-radius: 10px;
  background: var(--black, #1c1b20);
  height: 390px;
  margin-top: 1.25rem;
}
.faq-media video {
  width: 70%;
  height: 100%;
  /* display: block;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto; */
}
.card-details {
  padding: 1rem 1.8rem;
}
.card-details h4 {
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 900;
}
.number-date {
  border-right: 1px solid #f0eef5;
  padding-right: 1.85rem;
  /* border: 1px solid red; */
  flex: 1;
  flex-basis: 0;
  padding-left: 1.3rem;
}
.number-date h5 {
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  opacity: 0.5;
}
.number-date p {
  font-size: 1rem;
  font-style: normal;
  font-weight: 900;
}
.bar {
  height: 10px;
  border-radius: 10px;
  background: #f0eef5;
}
.fill {
  display: block;
  height: 100%;
  width: 40%;
  border-radius: 10px;
  background: #9747ff;
}
.fill-0 {
  width: 0% !important;
}
.spending-limit {
  flex: 1;
  flex-basis: 0;
  padding-left: 1.3rem;
  /* border: 1px solid red; */
}
.spending-limit h5 {
  font-size: 1rem;
  font-style: normal;
  font-weight: 900;
}
.card-img img {
  border-radius: 20px;
}
.card-details .dropdown-content {
  min-width: 210px;
  background-color: white;
}
.card-details .card-img {
  flex: 1;
  flex-basis: 0;
}
.cash-flow {
  margin-top: 1.3rem;
  border-radius: 20px;
  background: #f6f4f9;
}
.cash-flow h4 {
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  opacity: 0.5;
}
.cash-flow p {
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 900;
}
.cash-flow-item {
  flex: 1;
  flex-basis: 0;
  border-right: 1px solid #e9e9e9;
  padding: 1.5rem 1.3rem;
}
#send-amount {
  border: 1px solid rgba(255, 0, 0, 0);
}
.numpad-remove-btn img,
thead tr {
  pointer-events: none;
}
.pin-code {
  padding: 8rem 0;
  gap: 2.35rem;
}
.pin {
  display: block;
  width: 16px;
  height: 16px;
  background: #f0eef5;
  border-radius: 60px;
}
.switch {
  display: inline-block;
  height: 28px;
  position: relative;
  width: 54px;
}

.switch input {
  display: none;
}

.slider {
  background-color: #fff;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: -1px;
  transition: 0.4s;
  outline: 1px solid #ece9f3;
}

.slider:before {
  background-color: #f0eef5;
  bottom: 4px;
  content: "";
  height: 22px;
  left: 4px;
  position: absolute;
  transition: 0.4s;
  width: 22px;
}

input:checked + .slider {
  background-color: #b2edd3;
  border: none;
}

input:checked + .slider:before {
  background-color: #1c1b20;

  transform: translateX(22px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.loans-calculator {
  width: 40%;
  padding: 1.35rem 1.35rem;
  padding-bottom: 2rem;

  /* border: 1px solid red; */
}
.loans-information {
  width: 60%;
  padding: 1.35rem 1.5rem;
  padding-bottom: 2rem;

  /* border: 1px solid green; */
}
.loans-calculator h4,
.loans-information h4 {
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 900;
}
.loan-div {
  margin-top: 1.35rem;
  padding-bottom: 2rem;
}
.border-b-gray {
  border-bottom: 1px solid #f0eef5;
}
.loans-calculator .search-wrap input {
  padding-left: 37px;
}
.loans-calculator .search-wrap img {
  margin-left: 5px;
}
.loans-calculator input,
.loans-calculator .input-wrap {
  background: none;
}
.loan-amount-options {
  margin-top: 0.8rem;
  flex-wrap: wrap;
  gap: 0.45rem;
}
.loan-amount-options span {
  display: inline-block;
  /* margin-right: 0.15rem; */
  cursor: pointer;
  border-radius: 100px;
  background: #ecfaf4;
  padding: 7.5px 12px;
  border: 1px solid #33333302;
  font-size: 0.975rem;
  font-style: normal;
  font-weight: 700;
  /* flex: 1; */
  flex-basis: auto;
  text-align: center;
  transition: border 0.285s ease;
}
.loan-amount-options span:hover {
  border: 1px solid #333333be;
}

.select-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.custom-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #ffffff;
  border: 1px solid #f0eef5;
  width: 100%;
  padding: 0.9rem;
  width: 100%;
  border-radius: 10px;
  cursor: pointer;
}

.custom-select::placeholder {
  color: rgba(0, 0, 0, 0.5);
  opacity: 0.5;
}
.custom-select::placeholder {
  color: rgba(0, 0, 0, 0.3);
  opacity: 0.3;
}

.dropdown-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
}

.dropdown-icon img {
  width: 16px;
  height: 16px;
}
.selected {
  border: 1px solid #1c1b20 !important;
}
.per-month {
  margin-top: 1rem;
  margin-bottom: 2rem;
}
.per-month h2 {
  font-size: 3rem !important;
  font-style: normal;
  font-weight: 900;
  margin-bottom: 0rem !important;
}
.per-month p {
  margin-bottom: 0.5rem;
}
.loan-insights-data {
  margin-top: 2.25rem;
}
.loan-insights-graph {
  margin: 3rem auto;
  width: 80%;
}
.graph-point {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 6px;
}
.blue-point {
  background: var(--green, #b2edd3);
}
.purple-point {
  background: #9786db;
}
.yellow-point {
  background: #f9e18d;
}
.loans-form .form {
  text-align: left;
  padding: 1.5rem 1.3rem !important;
  justify-content: flex-start;
  height: auto;
  margin: 2rem 0;
  /* border: 1px solid red; */
}
.loans-form form {
  width: 100%;
}
.loans-form select {
  padding: 0.9rem 0.8rem;
  font-family: "Satoshi";
  font-size: 1rem;
  margin-top: 0rem;
}
.loans-form .btn {
  margin-top: 2rem;
}
.font-weight-500 {
  font-weight: 500 !important;
}
.filter-btns select {
  padding: 10px;
  border-radius: 7px;
}
.profile-avatar .dropdown-btn {
  border: none;
}
.profile-avatar .dropdown-content {
  padding: 0;
  /* padding-bottom: 2rem; */
  top: 100%;
}
.profile-avatar .dropdown-content .btn {
  /* border: 1px solid red; */
  border-radius: 15px;
  padding: 1rem 0;
  width: 100%;
}
.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.sidebar svg path {
  fill: #8e8d90 !important;
}
.sidebar .nav-active svg path {
  fill: #1c1b20 !important;
}
